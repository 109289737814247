//
// Part: Plugins
//

@import '../plugins/mixins';
@import '../plugins/fullcalendar';
@import '../plugins/select2';
@import '../plugins/bootstrap-slider';
@import '../plugins/icheck-bootstrap';
@import '../plugins/mapael';
@import '../plugins/jqvmap';
@import '../plugins/sweetalert2';
@import '../plugins/toastr';
@import '../plugins/pace';
@import '../plugins/bootstrap-switch';
@import '../plugins/miscellaneous';
