//
// Mixins: Navbar
//

// Navbar Variant
@mixin navbar-variant($color, $font-color: rgba(255, 255, 255, 0.8), $hover-color: #f6f6f6, $hover-bg: rgba(0, 0, 0, 0.1)) {
  background-color: $color;

  .nav > li > a {
    color: $font-color;
  }

  .nav > li > a:hover,
  .nav > li > a:active,
  .nav > li > a:focus,
  .nav .open > a,
  .nav .open > a:hover,
  .nav .open > a:focus,
  .nav > .active > a {
    background: $hover-bg;
    color: $hover-color;
  }

  // Add color to the sidebar toggle button
  .sidebar-toggle {
    color: $font-color;

    &:hover,
    &:focus {
      background: $hover-bg;
      color: $hover-color;
    }
  }
}
